<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Liste des proprietes</h3>
        </div>
        <div class="card-title">
          <b-button :variant="flagBtn ? 'info' : 'primary'" @click="searchEncours()">{{flagBtn ? 'En cours de travaux' : 'Tout' }}</b-button>
        </div>
        <div class="card-toolbar">
          <a class="btn btn-icon btn-sm btn-danger mr-1"  v-b-modal.search>
            <i class="icon-md fas fa-search"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <b-row>
          <b-col md="1">
            <select class="form-control" v-model="datatable.limitPage" @change="datatableInit">
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </b-col>
        </b-row>
        <b-table responsive striped hover ref="table" :items="datatable.data" :fields="fields" @sort-changed="sortChanged" show-empty empty-text="Il n'y a aucun enregistrement à afficher">
          <template #cell(numero_propriete)="data">
            <a :href="'/#/propriete/detail/'+data.item.id">{{ data.value }}</a>
          </template>
          <template #cell(id)="data">
            <a :href="'/#/propriete/detail/'+data.value">{{ data.value }}</a>
          </template>
          <template #cell(projet.titre)="data">
            <a :href="'/#/projet/detail/'+data.item.projet.id" v-if="data.item.projet.id != 0">{{ data.value }}</a>
            <span v-else>Aucun</span>
          </template>
          <template #cell(client.nom)="data">
            <a :href="'/#/client/detail/'+data.item.client.id">{{ data.value }} {{data.item.client.prenom}}</a>
          </template>
          <template #cell(type_id)="data">
            {{$store.back.getParamTitre(params.ProprieteType,data.value)}}
          </template>
          <template #cell(status_id)="data">
            {{$store.back.getParamTitre(params.ProprieteStatus,data.value)}}
          </template>
          <template #cell(solde)="data">
            {{ solde(data.item) }}
          </template>
        </b-table>
        <b-pagination size="lg" align="center" v-model="datatable.currentPage" @change="changePage" :total-rows="datatable.totalRow" :per-page="datatable.limitPage" aria-controls="itemList"></b-pagination>
      </div>
    </div>
    <b-modal id="search" title="Recherche" size="xl" ref="myModalDeleteRef">
      <div class="row">
        <div class="form-group col-md-4" v-for="(field,i) in fields.filter(f => f.searchable == true)" :key="i">
          <label>{{field.label}}</label>
          <input type="text" :placeholder="'Rechercher par '+field.label" :data-column="field.key" class="form-control">
        </div>
        <div class="form-group col-md-4">
          <label>Statut</label>
          <select class="form-control" data-column="status_id">
            <option value="">Tout</option>
            <option v-for="s in params.ProprieteStatus" :value="s.id" :key="s.id">{{s.titre.fr}}</option>
          </select>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="searchInit">
            Recherche
          </b-button>
          <b-button variant="danger" class="float-right mr-2" @click="$refs.myModalDeleteRef.hide();">
            Fermer
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data(){
    return {
      flagBtn:false,
      items: [],
      fields: [
        {
          key: 'numero_propriete',
          label: 'Numero bureau',
          sortable:false,
          searchable:true
        },
        {
          key: 'id',
          label: 'ID',
          sortable:true,
          searchable:true
        },
        {
          key: 'projet.titre',
          label: 'Projet',
          sortable:false,
          searchable:true
        },
        {
          key: 'client.nom',
          label: 'Client',
          sortable:false,
          searchable:true
        },        
        {
          key: 'type_id',
          label: 'Type',
          sortable:false,
          searchable:false
        },
        {
          key: 'status_id',
          label: 'Statut',
          sortable:false,
          searchable:false
        },
        {
          key: 'superficie',
          label: 'Superficie',
          sortable:true,
          searchable:false
        },
        {
          key: 'solde',
          label: 'Solde client',
          sortable:true,
          searchable:false
        }
      ],
      datatable:{
        currentPage: 1,
        nextPage: 2,
        previousPage: 0,
        limitPage: 20,
        totalRow: 0,
        data: []
      },
      sort:{
        field: 'id',
        value: 'DESC'
      },
      searchQuery:null,
      params:{
        status:[],
        ProprieteType:[],
        ProprieteStatus:[]
      }
    };
  },
  components: {
  },
  watch: {
  },
  beforeMount() {
    let params = {
      modules: ["ProprieteType","ProprieteStatus"]
    };
    this.$store.back.ajax('/magic/all', params, (res) => {
      if(res.status === true){
        this.params.ProprieteType = res.data['ProprieteType'];
        this.params.ProprieteStatus = res.data['ProprieteStatus'];
        this.params.status = res.data['status'];
      }
    });
  },
  methods: {
    datatableInit: function(){
      var the = this
      this.$store.back.ajax('/propriete/liste?'+this.query,null,(res) => {
        the.datatable = res;
      });
    },
    sortChanged(e){
      this.sort = {
        field: e.sortBy,
        value: e.sortDesc === true ? "DESC" : "ASC"
      };
      this.datatable.data = [];
      this.datatableInit();
    },
    changePage(e){
      this.datatable.currentPage = e;
      this.datatableInit();
    },
    searchInit(){
      this.datatable.currentPage = 1;
      let query = {};
      let inputs  = document.querySelectorAll('[data-column]');
      inputs.forEach(elt => {
        let value  = elt.value;
        let key = elt.dataset.column;
        query[key] = value
      });
      if(Object.entries(query).length > 0){
        this.searchQuery = encodeURI(JSON.stringify(query));
      }else{
        this.searchQuery = null;
      }
      this.$refs.myModalDeleteRef.hide();
      this.datatableInit();
    },
    searchEncours(){
      this.flagBtn = !this.flagBtn;
      var query = {};
      if(this.flagBtn){
        query.status_id = 2;
        this.searchQuery = encodeURI(JSON.stringify(query));
      }else{
        this.searchQuery = null;
      }
      this.datatableInit();
    },
    solde(propriete){
      var ht = 0;
      var tva = 0;
      var acompte = 0;
      console.log(propriete.compte_client);
      if(propriete.compte_client === undefined){
        return 0;
      }
      Object.values(propriete.compte_client).forEach((item) => {
        ht += parseFloat(item.prix_vente);
        tva += parseFloat(item.prix_vente)*(item.tva/100);
      });
      if(propriete.acompte_compte_client === undefined){
        return 0;
      }
      Object.values(propriete.acompte_compte_client).forEach((item) => {
        acompte += parseFloat(item.montant);
      });
      var total = (ht + tva) - acompte;
      return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'ILS' }).format(total);
    }
  },
  computed: {
    query: function(){
      var sort = this.sort.field === null ? null : encodeURI(JSON.stringify(this.sort));
      return 'page='+this.datatable.currentPage+'&limit='+this.datatable.limitPage+'&sort='+sort+'&search='+this.searchQuery;
    }
  },
  mounted() {
    this.searchEncours();
  }
};
</script>
